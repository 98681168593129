@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

@font-face {
  font-family: "prosperBold";
  src: url("../src/fonts/SCProsperSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "prosperMedium";
  src: url("../src/fonts/SCProsperSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "prosperRegular";
  src: url("../src/fonts/SCProsperSans-Regular.ttf") format("truetype");
}

.mediumF {
  font-family: "prosperMedium";
}

.boldF {
  font-family: "prosperBold";
}
.lightF {
  font-family: "prosperRegular";
}

.blue-gradient {
  background: linear-gradient(270deg, #2c3a87 0%, #0061c7 100%);
}
.blue_gradient_text {
  background: linear-gradient(90deg, #2c3a87 0%, #0061c7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.whiteText {
  color: white;
}
.button_gradient {
  background: #0473ea;
}

.button-hover {
  background: #061d33;
}
.link__hover:hover .appear {
  display: block;
}

.appear:hover {
  display: block;
}

.hiddenB {
  display: none;
}
.padding {
  padding-left: 10%;
  padding-right: 10%;
}
.paddingLeft {
  padding-left: 10%;
}
.hero_bg {
  background-image: url(../public/images/heroBg.png);
  background-position: top center;
  background-repeat: no-repeat;
  height: 704px;
  background-size: cover;
}

@media screen and (max-width: 650px) {
  .hero_bg {
    background-image: url(../public/images/hero-mobile.svg),
      url(../public/images/overlay-bg.svg);
    background-position: right;
    background-repeat: no-repeat;
    height: 500px;
    background-size: cover;
    background-blend-mode: multiply;
  }
}

.transition {
  transition: 0.5s ease;
}

@media screen and (max-width: 1300px) {
  .padding {
    padding-left: 5%;
    padding-right: 5%;
  }

  .paddingLeft {
    padding-left: 5%;
  }
}

@media screen and (max-width: 1000px) {
  .padding {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .paddingLeft {
    padding-left: 3rem;
  }
}

@media screen and (max-width: 650px) {
  .padding {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .paddingLeft {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .paddingLeft {
    padding-left: 1rem;
  }
}

.adds_container {
  background-image: url(../public/images/add.svg);
  height: 455px;
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact {
  background-image: url(../public/images/contactus.svg);
  background-repeat: no-repeat;
  height: 100%;
  background-position: right;
  background-size: cover;
}

@media screen and (max-width: 900px) {
  .contact {
    background-image: url(../public/images/contactus.svg),
      url(../public/images/overlay-white.png);
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-blend-mode: lighten;
  }
}

.footer-bg {
  background: linear-gradient(90deg, #2c3a87 0%, #0061c7 100%);
}

.service_bg {
  background-image: url(../public/images/serviceBg.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 320px;
  background-size: cover;
}

.contact_bg {
  background-image: url(../public/images/contactBg.png);
  background-position: center;
  background-repeat: no-repeat;
  height: 320px;
  background-size: cover;
}

.about_bg {
  background-image: url(../public/images/aboutBg.png);
  background-position: top;
  background-repeat: no-repeat;
  height: 320px;
  background-size: cover;
}

.global_bg {
  background-image: url(../public/images/world.png);
  background-position: center;
  background-repeat: no-repeat;
  height: 320px;
  background-size: cover;
}

.partnerBg {
  background-image: url(../public/images/partnerAbout.png);
  background-position: top center;
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.7);
  background-blend-mode: darken;
}
.first_gradient {
  background: linear-gradient(180deg, rgba(64, 64, 64, 0) 0%, #181818 78.64%);
  border-radius: 12px;
}

.grid1 {
  background-image: url(../public/images/grid1.png);
  height: 541px;
  background-size: cover;
  border-radius: 12px;
  background-position: center;
}

.grid2 {
  background-image: url(../public/images/grid2.png);
  width: 100%;
  height: 541px;
  background-size: cover;
  border-radius: 12px;
  background-position: center;
}

.grid3 {
  background-image: url(../public/images/grid3.png);
  width: 100%;
  height: 541px;
  background-size: cover;
  border-radius: 12px;
  background-position: center;
}

.grid4 {
  background-image: url(../public/images/grid4.png);
  height: 541px;
  width: 100%;
  background-size: cover;
  border-radius: 12px;
  background-position: center;
}

.grid5 {
  background-image: url(../public/images/grid5.png);
  height: 541px;
  background-size: cover;
  border-radius: 12px;
  background-position: center;
}

.mainGrid:hover .hiddenGrid {
  display: block;
}

@media screen and (max-width: 900px) {
  .grid1 {
    height: 400px;
    width: 100%;
  }

  .grid4 {
    height: 400px;
    width: 100%;
  }

  .grid2 {
    width: 100%;
    height: 400px;
  }

  .grid3 {
    width: 100%;
    height: 400px;
  }

  .grid5 {
    height: 400px;
  }
}

.global_image {
  background: lightgray 76px -35.547px / 71.904% 151.662% no-repeat;
}

/* new */

.accordion-content {
  display: none;
}

.accordion-content.open {
  display: block;
}

/* CSS for smooth transition */
.transition-opacity {
  transition: opacity 0.5s ease;
}

/* CSS for fade-in effect */
.fade-in {
  opacity: 1;
}

.newT {
  background: linear-gradient(270deg, #2c3a87 0%, #0061c7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 900px) {
  .partnerBg {
    background-image: url(../public/images/partnerAbout.png);
    background-position: top center;
    background-repeat: no-repeat;
    height: 100%;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: darken;
  }
}
